.heroFooter {
    display: none;
}
.topoIcon {
    display: none;
}
.wrapper_contacto>h5 {
    margin: 60px 132px 24px 36px;
    font-size: 30px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fffeed;
}

.container_contacto {
    display: flex;
    align-items: center;
    margin-left: 36px;
}

.container_contacto a {
    text-decoration: none;
    color: #a5a5a5;
    transition: 0.9s;
}

.container_contacto a:hover {
    text-decoration: none;
    color: #05f0d4;
    transition: 0.9s;
}

.container_contacto p:hover {
    text-decoration: none;
    color: #05f0d4;
    transition: 0.9s;
}

.footerIcon {
    padding-right: 16px;
    margin: 0 0 26px 0;
}

.container_contacto p {
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--green);
    margin: 0 0 8px 0;
    color: #05f0d4;
}

.container_contacto p::after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background: #05f0d4;
    transition: width .3s ease-in-out;
    margin-top: 5px;
}

.container_contacto p:hover::after {
    width: 100%;
}

.container_siguenos_element {
    display: flex;
    align-items: center;
}

.container_siguenos {
    margin: 20px 17.1px 32px 36px;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #a5a5a5;
}


.container_siguenos a {
    text-decoration: none;
    color: #05f0d4;
    transition: 0.9s;
}

.container_siguenos p {
    text-decoration: none;
    color: #05f0d4;
    transition: 0.9s;
    margin-bottom: 8px;
    margin-top: 0;
    display: block;
}

.container_siguenos p::after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background: #05f0d4;
    transition: width .3s ease-in-out;
    margin-top: 5px;
}

.container_siguenos p:hover::after {
    width: 100%;
}

.container_siguenos a:hover {
    color: #05f0d4;
    transition: 0.9s;
}

.container_siguenos>h5 {
    margin-bottom: 24px;
    color: #fffeed;
}

.container_paginas {
    margin: 60px 10.1px 60px 36px;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #a5a5a5;
    cursor: pointer;
}

.container_paginas a {
    text-decoration: none;
    color: #a5a5a5;
}

.container_paginas p:hover {
    color: #05f0d4;
    transition: 0.9s;
}

.container_paginas>h5 {
    color: #c4c4c4;
}

.smallText {
    text-align: center;

}

.smallText>small {
    font-size: 13px;
    font-family: "Apercu Light";
    color: #c4c4c4;
}

.smallText>small>a {
    text-decoration: none;
    color: #05f0d4;
}

.footerLogo {
    display: none;
}

/* Smartphones (landscape) ----------- */
@media only screen and (min-width : 321px) and (orientation : landscape) {
    .containerFooter {
        display: flex;
        justify-content: space-evenly;
    }

    .container_siguenos {
        margin-top: 60px;
        order: 2;
    }

    .container_siguenos h5 {
        margin-top: 50%;
    }

    .wrapper_contacto>h5 {
        margin: 74px 132px 26px 36px;
    }

    .wrapper_contacto {
        order: 1;
    }

    .footerLinks {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
    }

    .footerLogo {
        display: block;
        margin-left: 10%;
        order: 3;
        align-items: flex-end;

    }

    .footerLogo img {
        width: 60%;
    }

    .footerLogo p {
        margin-top: 0;
    }
}

/* iPads (portrait and landscape) ----------- */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    .footerLinks {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
    }

    .container_siguenos {
        margin-top: 60px;
        order: 2;
    }

    .wrapper_contacto>h5 {
        margin: 74px 132px 26px 36px;
    }

    .footerLogo {
        display: block;
        margin-left: 10%;
        order: 3;
        align-items: flex-end;

    }

    .footerLogo img {
        width: 60%;
    }

    .footerLogo p {
        margin-top: 0;
    }
}

/* iPads (landscape) ----------- */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {}

/* iPads (portrait) ----------- */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
    /* Styles */
}

/* Desktops and laptops ----------- */
@media only screen and (min-width : 1224px) {

    .footerLinks {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: end;
        margin: 0 6% 0 6%;
    }

    .topoIcon {
        display:block;
        position: absolute;
        right: 0;
        z-index: -1;
    }

    .containerFooter {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 7vw;
        margin-right: 7vw;
    }

    .containerHeroFooter {
        width: auto;
        display: flex;
        flex-direction: column;
        margin: 0 7vw 0 7vw;
        /* background-color: #181919; */
    }

    .heroFooter {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 100px;
        margin-top: 100px;
        margin-left: 3%
    }

    .hero_footer_capturamos {
        margin: 10px 52% 17px 10%;
        /* color: #fffeed; */
        color: #a5a5a5;
        animation-name: grow;
        animation-duration: 3s;
        animation-timing-function: ease-in-out;
    }

    .hero_footer_convertimos {
        margin: 0px 0 0 0;
        color: #fffeed;
        animation-name: grow;
        animation-duration: 3s;
        animation-timing-function: ease-in-out;
    }

    .growAnimation {
        animation-name: grow;
        animation-duration: 3s;
        animation-timing-function: ease-in-out;
    }

    .footerLogo {
        width: 200px;
        order: 1;
        margin-left: 0;
    }

    .footerLogo>p {
        color: #fffeed;
        margin-top: 8px;
    }

    .container_siguenos {
        display: flex;
        flex-direction: row;
        margin: 0px;
        justify-content: center;
        order: 2;
    }

    .container_siguenos_element {
        margin-left: 12px;
    }

    .container_siguenos>a {
        margin: 10px;
        padding: 0px;
    }

    .container_siguenos>h5 {
        display: none;
    }

    .container_contacto {
        margin-left: 0px;
    }

    .wrapper_contacto {
        display: flex;
        flex-direction: column;
        justify-content: center;
        order: 3;
    }


    .wrapper_contacto>h5 {
        display: none;
    }


    /* .smallText {
        background-color: #323231;
    } */

}

/* Large screens ----------- */
@media only screen and (min-width : 1824px) {
    /* Styles */
}

@keyframes grow {
    from {
        opacity: 0;
        -webkit-transform: scale(0.95);
        -ms-transform: scale(0.95);
        transform: scale(0.95);

    }

    to {
        opacity: 1;
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);

    }
}