.svgMenuClose {
  width: 40px;
  height: 40px;
}

.menu {
  position: fixed;
  top: 36px;
  right: 36px;
  z-index: 9;
  cursor: pointer;

}

.nav__container {
  position: fixed;
  top: 30px;
  right: 86px;
  width: 188px;
  height: 296px;
  background-color:rgba(51, 51, 51, 0.95);
  border-radius: 4px;
  z-index: 9;
  animation: menu 0.3s ease-in-out;

}

.menu_item::after {
  content: '';
  display: block;
  width: 0;
  height: 2px;
  background: #05F0D4;
  transition: width .3s;
  margin-top: 5px;
}

.menu_item:hover::after {
  width: 100%;
}

.nav__list {
  display: inline-block;
  padding: 0;
  margin: 0;
  margin-bottom: 10px;

}

.nav__list li:first-child {
  margin: 16px 0 4px 30px;
}

.nav__list li:last-child {
  padding-bottom: 41px;
}

.menuSeparator {
  border-bottom: 1px solid #ffef34;
  margin-right: 30px;
  margin-bottom: 10px;
  display: block !important;
}

.nav__list li {
  margin-left: 30px;
  list-style-type: none;
  color: #fff;
  overflow: hidden;
  /* font-family: 'Oswald', sans-serif; */
  font-size: 16px;
  font-weight: 300;
  line-height: 1.15;
  letter-spacing: 3px;
  padding-top: 10px;
  display: inline-block;
}

.nav__list_item a {
  text-decoration: none;
  color: antiquewhite;
  transition: 0.9s;
}

.nav__list_item a:hover {
  text-decoration: none;
  color: #05F0D4;
  transition: 0.9s;
}
.iconos {
  display: none;
}

@keyframes menu {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Smartphones (landscape) ----------- */
@media only screen and (min-width : 321px) and (orientation : landscape) {}

/* iPads (portrait and landscape) ----------- */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {}

/* iPads (landscape) ----------- */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {}

/* iPads (portrait) ----------- */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
  /* Styles */
}

/* Desktops and laptops ----------- */
@media only screen and (min-width : 1224px) {
  .svgMenuClose {
    width: 30px;
    height: 30px;
  }

  .nav__container {
    width: 388px;
    height: fit-content;
    padding-bottom: 20px
  }

  .menu_item::after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background: #05F0D4;
    transition: width .3s;
    margin-top: 5px;
  }

  .menu_item:hover::after {
    width: 100%;
  }

  .nav__list {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .nav__list li:first-child {
    margin: 20px 0 6px 30px;
  }

  .nav__list li:last-child {
    padding-bottom: 20px;
  }

  .menuSeparator {
    border-bottom: 1px solid #ffef34;
    margin-right: 30px;
    margin-bottom: 8px;
    display: block !important;
  }

  .nav__list li {
    font-size: 30px;
    padding-top: 36px;
    display: inline-block;
  }

  .nav__list_item a {
    display: inline-block;
  }

  .iconos {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding-bottom: 40px;
  }

  .footerIcon {
    width: 32px;
    margin-bottom: 40px;
  }
  

}

/* Large screens ----------- */
@media only screen and (min-width : 1824px) {
  /* Styles */
}