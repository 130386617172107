/* @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap'); */

@font-face {
    font-family: "Apercu Regular";
    src: url("/fonts/Apercu_Regular.otf");
    font-style: normal;
}

@font-face {
    font-family: "Apercu Light";
    src: url("/fonts/Apercu_Light.otf");
    font-style: normal;
}

@font-face {
    font-family: "Apercu Bold";
    src: url("/fonts/Apercu_Bold.otf");
    font-style: normal;
}

:root {
    --yellow: #ffef34;
    --white: #fffeed;
    --grey: #323231;
    --green: #05f0d4;
}

html {
    scroll-behavior: smooth;
}

body {
    font-family: 'Apercu Regular';
    font-weight: 300;
    font-size: 16px;
    line-height: 140%;
    color: #c4c3ca;
    background-color: #181919;
    overflow-x: hidden;
    -webkit-transition: all 300ms linear;
    transition: all 300ms linear;
}

h1 {
    font-size: 49px;
    font-family: "Apercu Regular";
    line-height: 120%;

}

h2 {
    font-size: 39px;
    font-family: "Apercu Regular";
    line-height: 130%;

}

h3 {
    font-size: 31px;
    font-family: "Apercu Regular";
    line-height: 130%;

}

h4 {
    font-size: 25px;
    font-family: "Apercu Regular";
    margin: 0px 0px 12px;
    line-height: 140%;

}

h5 {
    font-size: 20px;
    font-family: "Apercu Regular";
    line-height: 140%;

}

h6 {
    font-size: 16px;
    font-family: "Apercu Regular";
    line-height: 140%;

}

@media only screen and (min-width : 500px)  {

    body {
        font-family: 'Apercu Regular';
        font-weight: 300;
        font-size: 18px;
    }

    h1 {
        font-size: 75px;
        font-family: "Apercu Regular";
    }
    
    h2 {
        font-size: 56px;
        font-family: "Apercu Regular";
    }
    
    h3 {
        font-size: 42px;
        font-family: "Apercu Regular";
    }
    
    h4 {
        font-size: 32px;
        font-family: "Apercu Regular";
    }
    
    h5 {
        font-size: 24px;
        font-family: "Apercu Regular";
    }
    
    h6 {
        font-size: 18px;
        font-family: "Apercu Regular";
    }
}